import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { CheckMarkItem, CheckMarkList } from "../components/elements/CheckMarkList";
import { Button, ButtonPrimary } from "../components/elements/Button";
import { Container } from "../components/elements/Container";
import { Hero, VideoBackground } from "../components/elements/Hero";
import Section from "../components/elements/Section";
import SEO from "../components/elements/SEO";
import { Caption, Overview, Title } from "../components/elements/Text";
import { Video, VideoWithControls } from "../components/elements/Video";
import { VideoList, YoutubeEmbed } from "../components/elements/VideoList";
import Wrap from "../components/elements/Wrap";
import Layout from "../components/layout/Layout";
import { maxMedia } from "../styles/utils/media-queries";
import { Software } from "../types/partials";

const FeatureHero = styled(Hero)`
  height: 28rem;
  overflow: hidden;
`;

const FeatureSection = styled(Section)`
  ${maxMedia.mobile`
    padding: 0;
    padding-bottom: 4rem;
  `}
`;

const StyledTitle = styled(Title)`
  top: -80px;
  position: absolute;

  ${maxMedia.mobile`
    top: -94px;
  `}
`;

const imgStyle = {
  objectFit: 'cover',
  minWidth: '101%',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  height: '100%',
}

function SoftwarePage({ pageContext }) {
  const data: Software = pageContext;

  React.useEffect(() => {
    // trick the scroll listener to trigger when the feature page is loaded
    window.scrollTo(0, 1);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout seamlessTop>
      <SEO title={data.name} description={data.seo_description} />
      <FeatureHero>
        <VideoBackground style={{ opacity: ".4" }}>
          {
          data.banner?.file.publicURL ? 
            (<Video alt={`${data.name} banner`} src={data.banner?.file.publicURL} />) 
            : (data.placeholderImage?.file.publicURL ? <img src={data.placeholderImage?.file.publicURL} style={imgStyle} /> : null )
          }
        </VideoBackground>
      </FeatureHero>
      <Container>
        <FeatureSection paddingBottom paddingTop>
          <Wrap>
            <div>
              <StyledTitle>{data.name}</StyledTitle>
              <Caption>{data.summary}</Caption>
              <Overview>{data.description}</Overview>
              <div style={{ margin: "2rem 0", display: "flex" }}>
                <Link to={"/contact"}>
                  <ButtonPrimary style={{ marginRight: "1rem" }}>Learn More</ButtonPrimary>
                </Link>
                {data.pdf?.file.publicURL && (
                  <a href={data.pdf?.file.publicURL} download={data.name}>
                    <Button>
                      <FontAwesomeIcon icon={faDownload} />
                      Product presentation
                    </Button>
                  </a>
                )}
              </div>
            </div>
            <CheckMarkList>
              {(data.features || []).map((feature, i) => (
                <CheckMarkItem key={i} text={feature.value} />
              ))}
            </CheckMarkList>
          </Wrap>
        </FeatureSection>
      </Container>
      {!!data.videos?.length || !!data.videos_from_file?.length ? (
        <Section secondary paddingBottom paddingTop>
          <Container>
            <VideoList>
              {/* YOUTUBE VIDEOS */}
              {(data.videos || []).map(({ url }, i) => (
                <YoutubeEmbed key={`youtube-${i}`} url={url} />
              ))}
              {/* LOCAL FILE VIDEOS */}
              {(data.videos_from_file || []).map(({ file }, i) => (
                file?.publicURL && (
                  // Locally enable "controls" for local videos
                  // corresponding to video.setAttribute("controls")
                  // in Video.tsx
                  <VideoWithControls
                    key={`local-video-${i}`}
                    alt={`${data.name} local video #${i}`}
                    src={file.publicURL}
                  />
                )
              ))}
            </VideoList>
          </Container>
        </Section>
      ) : null}
    </Layout>
  );
}

export default SoftwarePage;
